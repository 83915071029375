import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListProjectPage from '../../features/project/pages/ListProjectPage';
import CreateProjectPage from '../../features/project/pages/CreateProjectPage';
import EditProjectPage from '../../features/project/pages/EditProjectPage';
import ListContactUsPage from '../../features/contactUs/pages/ListContactUsPage';
import ListSubscriptionPage from '../../features/subscription/pages/ListSubscriptionPage';
import ListPublicationPage from '../../features/publication/pages/ListPublicationPage';
import CreatePublicationPage from '../../features/publication/pages/CreatePublicationPage';
import EditPublicationPage from '../../features/publication/pages/EditPublicationPage';
import ListGalleryPage from '../../features/gallery/pages/ListGalleryPage';
import CreateGalleryPage from '../../features/gallery/pages/CreateGalleryPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        {/* Project  */}
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <ListProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/create"
          element={
            <PrivateRoute>
              <CreateProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/edit/:id"
          element={
            <PrivateRoute>
              <EditProjectPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/contact-us"
          element={
            <PrivateRoute>
              <ListContactUsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <ListSubscriptionPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/publications"
          element={
            <PrivateRoute>
              <ListPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/create"
          element={
            <PrivateRoute>
              <CreatePublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/edit/:id"
          element={
            <PrivateRoute>
              <EditPublicationPage />
            </PrivateRoute>
          }
        />

        {/* Gallery  */}
        <Route
          path="/galleries"
          element={
            <PrivateRoute>
              <ListGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/create"
          element={
            <PrivateRoute>
              <CreateGalleryPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
