const baseUrl = 'https://api.hanzconsulting.org';

const constants = {
  apiUrl: `${baseUrl}/api`,
  mediaUrl: `${baseUrl}/media`,
  authUrl: 'auth',
  keys: {
    user: 'user',
  },
  prefixes: {
    sorting: 'sorting',
    visibility: 'visibility',
    density: 'density',
    rowsPerPage: 'rows_per_page',
  },
};

export default constants;
