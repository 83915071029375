
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { PublicationCreate } from "../publication";
import { Grid, TextField, FormHelperText, Box, Paper, Button } from "@mui/material";
import ReactQuill from "react-quill";
import { richTextModules } from "../../../core/utils/utility";


type PublicationProps = {
  onSubmit: (
    value: PublicationCreate,
    helpers: FormikHelpers<PublicationCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreatePublicationForm = ({
  onSubmit,
  submitting,
  
}: PublicationProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: PublicationCreate,
    helpers: FormikHelpers<PublicationCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/publications/create', { replace: true });
    };
  };

  const initialValues: PublicationCreate =  {    title: '',
    description: '',
    content: '',
    image: null,
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
    <>
      <Box sx={{ flexGrow: 1, width: 1200 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
          
<Grid item xs={12} sm={9}>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>

<Grid item sx={{ flexGrow: 1 }}></Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="description"
    size="small"
    type="text"
    variant="outlined"
    label="Description"
    placeholder=""
    value={formik.values.description}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.description)}
    helperText={formik.errors.description}
    multiline
    rows={6}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    Content
  </Grid>
  <ReactQuill
    modules={richTextModules}
    value={formik.values.content}
    onChange={(e) => {
      formik.setFieldValue('content', e);
    }}
    placeholder=""
  />
  {formik.touched.content && formik.errors.content && (
    <FormHelperText error>
      {formik.errors.content}
    </FormHelperText>
  )}
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    Image
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'image',
          files && files.length ? files[0] : null
        );
      }}
      name="image"
    />
  </Box>
  {formik.touched.image && formik.errors.image && (
    <FormHelperText error>
      {formik.errors.image}
    </FormHelperText>
  )}
</Grid>

<Grid item sx={{ flexGrow: 2 }}></Grid>
</Grid>
  </Paper>
</Grid>

          
<Grid item xs={12} sm={3}>
  <Grid container>
    <Button
      type="submit"
      variant="contained"
      disabled={submitting}
      size="small"
    >
      {submitting ? 'Saving' : 'Save'}
    </Button>
  </Grid>
</Grid>

          </Grid>
        </form>
      </Box>
    </>
  
  );
};

export default CreatePublicationForm;
