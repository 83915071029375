import { AlternateEmail, Description, Email, Image } from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/contact-us"
                label="Contact Us"
                active={location.pathname.startsWith('/contact-us')}
                icon={<Email />}
              />
              <SidebarLink
                to="/galleries"
                label="Gallery"
                icon={<Image />}
                active={location.pathname.startsWith('/galleries')}
              />
              <SidebarLink
                to="/projects"
                label="Project"
                active={location.pathname.startsWith('/projects')}
              />
              <SidebarLink
                to="/publications"
                label="Publication"
                active={location.pathname.startsWith('/publications')}
                icon={<Description />}
              />
              <SidebarLink
                to="/subscription"
                label="Subscription"
                active={location.pathname.startsWith('/subscription')}
                icon={<AlternateEmail />}
              />
            </Grid>

            {/* <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                SINGLE TYPES
              </Typography>
              <SidebarLink
                to="/about"
                label="About"
                active={location.pathname.startsWith('/about')}
                icon={<Business />}
              />
            </Grid> */}
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
