import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import { ProjectCreate } from "../project";
import CreateProjectForm from "../components/CreateProjectForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const CreateProjectContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  

  const handleSubmit = async (
    values: ProjectCreate,
    { setFieldError }: FormikHelpers<ProjectCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: 'projects',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Project Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/projects" />}>
      
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Project</Typography>
        </Grid>
        <CreateProjectForm  onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
      
    </Layout>
  );
};

export default CreateProjectContainer;
