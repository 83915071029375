import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ProjectCreate } from '../project';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
} from '@mui/material';

type ProjectProps = {
  onSubmit: (
    value: ProjectCreate,
    helpers: FormikHelpers<ProjectCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateProjectForm = ({ onSubmit, submitting }: ProjectProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: ProjectCreate,
    helpers: FormikHelpers<ProjectCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/projects/create', { replace: true });
    }
  };

  const initialValues: ProjectCreate = {
    title: '',
    description: '',
    status: '',
    sectors: [],
    image: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: 600 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.status)}
                      fullWidth
                    >
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={formik.values.status}
                        size="small"
                        label="Status"
                        onChange={formik.handleChange}
                        name="status"
                      >
                        <MenuItem value="in_progress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                      </Select>
                      {Boolean(formik.errors.status) && (
                        <FormHelperText>{formik.errors.status}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.sectors)}
                      fullWidth
                    >
                      <InputLabel>Sectors</InputLabel>
                      <Select
                        value={formik.values.sectors}
                        size="small"
                        label="Sectors"
                        multiple
                        onChange={({ target: { value } }) => {
                          formik.setFieldValue(
                            'sectors',
                            typeof value === 'string' ? value.split(',') : value
                          );
                        }}
                        name="sectors"
                      >
                        <MenuItem value="analysis">Analysis</MenuItem>
                        <MenuItem value="data_and_evidence">
                          Data and Evidence
                        </MenuItem>
                        <MenuItem value="knowledge_and_insight">
                          Knowledge and insight
                        </MenuItem>
                        <MenuItem value="strategy_and_recommendation">
                          Strategy and Recommendation
                        </MenuItem>
                      </Select>
                      {Boolean(formik.errors.sectors) && (
                        <FormHelperText>{formik.errors.sectors}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateProjectForm;
