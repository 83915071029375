import { Grid } from '@mui/material';
import Layout from '../../../core/ui/layout/Layout';
import ProfileContainer from '../containers/ProfileContainer';

const ProfilePage = () => {
  return (
    <>
      <Layout>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ my: 1 }}
        >
          <Grid item>
            <ProfileContainer />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default ProfilePage;
