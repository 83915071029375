import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { GalleryCreate } from '../gallery';
import { Grid, Box, Paper, Button, FormHelperText } from '@mui/material';

type GalleryProps = {
  onSubmit: (
    value: GalleryCreate,
    helpers: FormikHelpers<GalleryCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateGalleryForm = ({ onSubmit, submitting }: GalleryProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: GalleryCreate,
    helpers: FormikHelpers<GalleryCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/galleries/create', { replace: true });
    }
  };

  const initialValues: GalleryCreate = { image: null };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: 400 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateGalleryForm;
